import React from 'react';
import { Layout, SEO } from '../layout';
import { motion } from 'framer-motion';
import WithTransition from '../components/WithTransition';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const AboutPage = () => (
	<Layout>
		<SEO title="Sobre" />
		<section className="section-padding">
			<div className="container flow">
				<div className="container_about">
					<div className="container_header">
						<div className="container_text">
						<motion.img
							whileTap={{ scale: 0.9 }}
							drag={true}
							dragConstraints={{
								left: 0,
								right: 250,
								top: 0,
								bottom: 50,
							}}
							initial={{ opacity: 0, y: -100 }}
							animate={{
								opacity: 1,
								y: 0,
								transition: { duration: 1 },
							}}
							
							src="../images/mire_pi_logo.svg"
						/>
						</div>
						<div className="container_image_text">
							<StaticImage
								src="../images/IMG_9946.JPG"
								className="image_about"
							/>
							<p className="text_about">
								Artista não binárie atuante na dança,
								performance, palhaçaria e arte educação, produz
								seus trabalhos de maneira autoral com parcerias
								entre artistas interdisciplinares, partindo de
								perspectivas relacionadas ao pós-pornô,
								erotismo, horror, a comicidade e a educação
								somática, como fundamento investigativo para
								suas criações. Atualmente está se graduando em
								licenciatura em dança na Universidade Federal de
								Alagoas. Sua trajetória é marcada pela
								improvisação em espaços públicos e a dança é um
								dispositivo político desviante na produção de
								novas interações, imaginários e existências. Foi
								Co-fundadore da coletiva cênica “Corpatômica”
								com mais três artistas para discutir questões de
								gênero, sexualidade e violências estruturais,
								estreando três de seus trabalhos mais recentes,
								<a className="link-jobs-about" href="/mamilos">
									"Mamilos"
								</a>{' '}
								é um espetáculo de dança e performance que aborda
								questões de gênero e violências atribuídas nesse
								contexto, apresentado no Sesc Aldeia Arapiraca e
								Sesc das Artes no estado de Alagoas em 2019.
								"Dança Presente" é uma intervenção urbana cênica
								que convoca o olhar para aos crimes de
								feminicídio ocorridos no Brasil e uma homenagem
								a memória destas vidas que foram brutalmente
								interrompidas, apresentado nas ruas de Alagoas
								em 2020.{' '}
								<a
									className="link-jobs-about"
									href="/mulher-pandemica">
									"Mulher Pandêmica" 
								</a>{' '}
								é um curta metragem ficcional produzido em
								parceria com o Studio Piracema e a Casa Sede,
								onde retrata o cotidiano pandêmico de diversas
								mulheridades, estreado em 2020 na mostra oficial
								de cinema Alagoano Mostra Sururu e participou de
								alguns festivais virtuais nacionais e
								internacionais.{' '}
								<a className="link-jobs-about" href="/sargaco">
									"Sargaço"
								</a>{' '}
								é seu primeiro solo de dança onde a pesquisa se
								debruça em questões de ordem da experiência
								erótica e das qualidades de interação entre
								corpo-superfície-imagem, foi realizado através
								da lei emergencial Aldir Blanc do estado de
								Alagoas em conjunto com a Secretaria do Estado
								da Cultura (SECULTAL) e foi adaptado para o
								formato audiovisual estreado em 2021.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	</Layout>
);

export default WithTransition(AboutPage);
